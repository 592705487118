import { createBrowserRouter } from 'react-router-dom';
import App from '../App';
import { Suspense, lazy } from 'react';

const ErrorPage = lazy(() => import('../error-page'));
const ListingPage = lazy(() => import('../containers/ListingPage/ListingPage'));
const VerifiedEmail = lazy(() => import('../components/Verified/VerifiedEmail'));
const ResetPassword = lazy(() => import('../components/Verified/ResetPassword'));
const ProfileSettingsForm = lazy(() => import('../components/ProfileSettingsForm/ProfileSettingsForm'));
const ShowListingPage = lazy(() => import('../containers/ShowListingPage/ShowListingPage'));
const ListingDetailsPage = lazy(() => import('../containers/ListingDetailsPage/ListingDetailsPage'));
const AuthenticationPage = lazy(() => import('../containers/Authentication Page/AuthenticationPage'));
const PrivacyPolicyPage = lazy(() => import('../containers/PrivacyPolicyPage/PrivacyPolicyPage'));
const TermsOfServicePage = lazy(() => import('../containers/TermsOfServicePage/TermsOfServicePage'));
const AgentSignup = lazy(() => import('../components/Verified/AgentSignup'));
const AboutUsPage = lazy(() => import('../containers/AboutUsPage/AboutUsPage'));
const FaqPage = lazy(() => import('../containers/FaqPage/FaqPage'));
const ContactUsPage = lazy(() => import('../containers/ContactUsPage/ContactUsPage'));
const SearchPage = lazy(() => import('../containers/SearchPage/SearchPage'));
const InboxPage = lazy(() => import('../containers/InboxPage/InboxPage'));
const TransactionPage = lazy(() => import('../containers/TransactionPage/TransactionPage'));
const BookingDetailPage = lazy(() => import('../containers/BookingDetailPage/BookingDetailPage'));
const AdminPanel = lazy(() => import('../containers/AdminPage/AdminPanel'));
const UserAdminComponent = lazy(() => import('../containers/AdminPage/AdminComponents/UserAdminComponent'));
const SubscriptionPage = lazy(() => import('../containers/Subscription/SubscriptionPage'));
const MySubscriptionPage = lazy(()=>import('../containers/MySubcriptionPage/MysubcriptionPage'));
const SuccessPage = lazy(() => import('../containers/Subscription/SuccessPage/SuccessPage'));
const FailPage = lazy(() => import('../containers/Subscription/FailPage/FailPage'));
const ArtistSignup = lazy(() => import('../components/Verified/ArtistSignup'));



export const router = createBrowserRouter([
    {
        path: "/",
        element: <App />,
        errorElement: <ErrorPage />,
    },
    {
        path: "/subscription",
        element: <Suspense>
            <SubscriptionPage />
        </Suspense>,
        errorElement: <ErrorPage />
    },
    {
        path:"/my-subscription",
        element: <Suspense>
        <MySubscriptionPage />
        </Suspense>,
        errorElement: <ErrorPage />
    },
    {
        path: "/subs-success",
        element: <Suspense>
            <SuccessPage />
        </Suspense>,
        errorElement: <ErrorPage />
    },
    {
        path: "/subs-cancel",
        element: <Suspense>
            <FailPage />
        </Suspense>,
        errorElement: <ErrorPage />
    },
    {
        path: "/auth/:authName",
        element: <Suspense>
            <AuthenticationPage />
        </Suspense>,
        errorElement: <ErrorPage />
    },
    {
        path: "/reset-password",
        element: <Suspense>
            <ResetPassword />
        </Suspense>,
        errorElement: <ErrorPage />
    },
    {
        path: "/listing/:tab/:id/:slug",
        element: <Suspense>
            <ListingPage />
        </Suspense>,
        errorElement: <ErrorPage />,

    },
    {
        path: "/verify",
        element: <Suspense>
            <VerifiedEmail />
        </Suspense>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/agent-invitation",
        element: <Suspense>
            <AgentSignup />
        </Suspense>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/artist-invitation",
        element: <Suspense>
            <ArtistSignup />
        </Suspense>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/update-profile",
        element: <Suspense>
            <ProfileSettingsForm />
        </Suspense>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/show-listings",
        element: <Suspense>
            <ShowListingPage />
        </Suspense>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/l/:table/:id",
        element: <Suspense>
            <ListingDetailsPage />
        </Suspense>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/privacy-policy",
        element: <Suspense>
            <PrivacyPolicyPage />
        </Suspense>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/terms-of-service",
        element: <Suspense>
            <TermsOfServicePage />
        </Suspense>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/about-us",
        element: <Suspense>
            <AboutUsPage />
        </Suspense>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/faq",
        element: <Suspense>
            <FaqPage />
        </Suspense>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/contact-us",
        element: <Suspense>
            <ContactUsPage />
        </Suspense>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/:tab/s",
        element: <Suspense>
            <SearchPage />
        </Suspense>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/inbox/:type/:state",
        element: <Suspense>
            <InboxPage />
        </Suspense>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/inbox/:type/:state/:id",
        element: <Suspense>
            <BookingDetailPage />
        </Suspense>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/order",
        element: <Suspense>
            <TransactionPage />
        </Suspense>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/admin/:tab",
        element: <Suspense>
            <AdminPanel />
        </Suspense>,
        errorElement: <ErrorPage />,
        children: [
            {
                path: ":usertype",
                element: <Suspense>
                    <UserAdminComponent />
                </Suspense>,
                errorElement: <ErrorPage />,
            }
        ]
    },
    {
        path: "/*",
        element: <h1>Page Not Found</h1>
    }
])