import FormatText from "../components/FormatText/FormatText";

export const CANCELLED = "Cancelled";

export const userRoles = [
  { label: "Artist", key: "artist", value: "artist" },
  { label: "Agent", key: "agent", value: "agent" },
  { label: "Venue-Manager", key: "venueManager", value: "venueManager" },
];

export const languages = [
  { key: "en", label: "English" },
  { key: "it", label: "Italian" },
];

export const FREE_PLAN = "Free-Tier-Subscription-monthly";

export const IN_SINGLE_PAGE = process.env.REACT_APP_IN_SINGLE_PAGE;
export const MAX_MOBILE_SCREEN_WIDTH = 767;
export const FULFILLED = "fulfilled";
export const CUSTOMER = "customer";
export const VENUES = "venues";
export const SHOWS = "shows";
export const AUTHOR = "provider";
export const VENUE_MANAGER = "venueManager";
export const AGENT = "agent";
export const ARTIST = "artist";
export const ADMIN = "admin";
export const LOGIN = "login";
export const SIGNUP = "signup";

export const LISTING_TYPE_NEW = "new";
export const LISTING_TYPE_DRAFT = "draft";
export const LISTING_TYPE_PUBLISHED = "published";

export const LISTING_SHOWS = "shows";
export const LISTING_VENUES = "venues";

export const VERIFIED = "Verified";
export const UNVERIFIED = "Not Verified";

export const SHOWSLISTING = "showslistings";
export const VENUESLISTING = "venueslistings";

export const select_yes_no = [
  { label: "Yes", key: "yes", value: true },
  { label: "No", key: "no", value: false },
];

export const currency = [
  { label: "EUR", key: "eur", symbol: "€" },
  // { label: "USD", key: "usd", symbol: "$" },
  // { label: "GBP", key: "gbp", symbol: "£" },
];
export const price_venue = [
  { label: "PerDay", key: "daily", value: "daily-rate" },
  { label: "PerHour", key: "hourly", value: "hourly-rate" },
];
export const Numbermicrophones = [
  ...[...new Array(10)].map((item, index) => ({
    label: index + 1,
    key: index + 1,
    value: index + 1,
  })),
  { label: "10+", key: "10+", value: 100 },
];

export const percentage = [...new Array(100)].map((each, index) => ({
  label: index + 1,
  value: index + 1,
}));

export const shows_payment_method = [
  {
    label: "Revenue_Sharing",
    key: "Revenue Sharing",
    value: "Revenue Sharing",
  },
  { label: "Fee_Structure", key: "Fee Structure", value: "Fee Structure" },
];
export const AUTH_SIGNUP = "signup";

export const allGenres = [
  { label: "Comedy", key: "Comedy", value: "Comedy" },
  { label: "Cabaret", key: "Cabaret", value: "Cabaret" },
  { label: "Concert", key: "Concert", value: "Concert" },
  { label: "Lesson", key: "Lesson", value: "Lesson" },
  { label: "Poetry", key: "Poetry", value: "Poetry" },
  { label: "Other", key: "Other", value: "Other" },
];

export const optionalSelect = [
  { key: "yes", value: "yes", label: "Yes" },
  { key: "no", value: "no", label: "No" },
  { key: "optional", value: "optional", label: "Optional" },
];
export const YES = "yes";
export const OPTIONAL = "Optional";
export const NO = "no";
export const AVAILABLE = "Available";
export const UNAVAILABLE = "Not Available";
export const draftId = "00000000-0000-0000-0000-000000000000";
export const draftSlug = "new";
export const searchArray = ["Browse venues", "Browse Shows", "Build your Page"];
export const DUMMY_CODE = "xxxx-xxxx-xxxx-xxxx";
export const DUMMY_MONEY = "xx.xx";
export const MIN_CAPACITY = 0;
export const MAX_CAPACITY = 10000;
export const STEP_CAPACITY = 100;
export const MIN_LENGTH = 0;
export const MAX_LENGTH = 100;
export const STEP_LENGTH = 1;
export const MIN_WIDTH = 0;
export const MAX_WIDTH = 100;
export const STEP_WIDTH = 1;
export const MIN_HEIGHT = 0;
export const MAX_HEIGHT = 100;
export const STEP_HEIGHT = 1;

export const days = [
  { label: "Monday", key: "mon" },
  { label: "Tuesday", key: "tue" },
  { label: "Wednesday", key: "wed" },
  { label: "Thursday", key: "thu" },
  { label: "Friday", key: "fri" },
  { label: "Saturday", key: "sat" },
  { label: "Sunday", key: "sun" },
];

export const breakpoints = {
  // when window width is >= 320px
  320: {
    slidesPerView: 1,
    spaceBetween: 10,
  },
  // when window width is >= 480px
  480: {
    slidesPerView: 2,
    spaceBetween: 10,
  },
  // when window width is >= 768px
  768: {
    slidesPerView: 2,
    spaceBetween: 20,
  },
  // when window width is >= 992px
  992: {
    slidesPerView: 3,
    spaceBetween: 20,
  },
};

export const breakpointsLDP = {
  // when window width is >= 320px
  320: {
    slidesPerView: 1,
    spaceBetween: 10,
  },
  // when window width is >= 480px
  480: {
    slidesPerView: 1,
    spaceBetween: 10,
  },
  // when window width is >= 768px
  768: {
    slidesPerView: 1,
    spaceBetween: 20,
  },
  // when window width is >= 992px
  992: {
    slidesPerView: 1,
    spaceBetween: 20,
  },
};

export const showsListingTabs = [
  { tab: "details", tabLabel: FormatText("DetailForm.ShowListingHeading") },
  {
    tab: "agent-details",
    tabLabel: FormatText("AgentDetailForm.ShowAgentDetailHeading"),
  },
  {
    tab: "technical-requirement",
    tabLabel: FormatText("TechinalRequirementForm.ShowTechinalHeading"),
  },
  {
    tab: "availibility",
    tabLabel: FormatText("CalendarForm.ShowCalendarTabHeading"),
  },
  {
    tab: "artist-details",
    tabLabel: FormatText("ArtistDetailsForm.ShowArtistDetailTabHeading"),
  },
];
export const venueListingTabs = [
  {
    tab: "venue-details",
    tabLabel: FormatText("VenueListing.DetailFormTabTitle"),
  },
  { tab: "contacts", tabLabel: FormatText("Venuelisting.ContactFormTabTitle") },
  {
    tab: "stage-details",
    tabLabel: FormatText("VenueListing.StagingDetailsFormTabTitle"),
  },
  {
    tab: "files-and-dates",
    tabLabel: FormatText("VenueListing.FilesAndDatesFormTabTitle"),
  },
  { tab: "pricing", tabLabel: FormatText("VenueListing.PricingFormTabTitle") },
];

export const venueTypes = [
  { label: "Theater", key: "Theater", value: "Theater" },
  { label: "ConcertHall", key: "Concert Hall", value: "Concert hall" },
  { label: "Forum", key: "Forum", value: "Forum" },
  { label: "Festival", key: "Festival", value: "Festival" },
  { label: "ConferenceRoom", key: "Conference Room", value: "Conference room" },
  { label: "Club", key: "Club", value: "Club" },
  { label: "OpenAir", key: "Open air", value: "Open air" },
  { label: "Arena", key: "Arena", value: "Arena" },
  { label: "Other", key: "Other", value: "Other" },
];
export const allSubscriptions = [
  {
    label: "Free Tier Subscription Plan",
    key: "Free-Tier-Subscription-monthly",
    value: 1,
  },
  {
    label: "Mid Tier Subscription Plan",
    key: "Mid-Tier-Subscription-monthly",
    value: 10,
  },
  {
    label: "Full Tier Subscription Plan",
    key: "Full-Tier-Subscription-monthly",
    value: 100,
  },
];
export const APPROVED = "APPROVED";
export const PENDING = "PENDING";
export const REJECTED = "REJECTED";
export const REQUEST_CHANGE = "Request";
export const ACTIVE = "Active";
export const EXPIRED = "EXPIRED";
export const timeArray = [
  { key: "00:00", label: "12:00 am" },
  { key: "01:00", label: "01:00 am" },
  { key: "02:00", label: "02:00 am" },
  { key: "03:00", label: "03:00 am" },
  { key: "04:00", label: "04:00 am" },
  { key: "05:00", label: "05:00 am" },
  { key: "06:00", label: "06:00 am" },
  { key: "07:00", label: "07:00 am" },
  { key: "08:00", label: "08:00 am" },
  { key: "09:00", label: "09:00 am" },
  { key: "10:00", label: "10:00 am" },
  { key: "11:00", label: "11:00 am" },
  { key: "12:00", label: "12:00 pm" },
  { key: "13:00", label: "01:00 pm" },
  { key: "14:00", label: "02:00 pm" },
  { key: "15:00", label: "03:00 pm" },
  { key: "16:00", label: "04:00 pm" },
  { key: "17:00", label: "05:00 pm" },
  { key: "18:00", label: "06:00 pm" },
  { key: "19:00", label: "07:00 pm" },
  { key: "20:00", label: "08:00 pm" },
  { key: "21:00", label: "09:00 pm" },
  { key: "22:00", label: "10:00 pm" },
  { key: "23:00", label: "11:00 pm" },
];
