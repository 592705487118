import React, { useState } from "react";
import css from "./Footer.module.scss";
import Container from "../Container/Container";
import { Link } from "react-router-dom";
import Logo from "../Logo/Logo";
import imgUrl from "../../assets/header_logo.png";
import footerLogo from "../../assets/footer_logo.png";
import { languages } from "../../constants/data";

const Footer = () => {
  const [language, setLanguage] = useState(localStorage.getItem("language"));

  const onChange = (e) => {
    const val = e.target.value;
    setLanguage(val);
    localStorage.setItem("language", val);
    window.location.reload();
  }

  return (
    <div className={css.footerSection}>
      <Container>
        <div className={css.footerTopContents}>
          <div>
            <Link to="/">
              <div className={css.footerLogo}>
                <Logo src={imgUrl} alt="Header Logo" />
              </div>
            </Link>
          </div>
          <div className={css.footerLinks}>
            <div>
              <ul>
                <li>Change language</li>
                <li>
                  <select
                    name="language"
                    id="language"
                    onChange={onChange}
                    value={language}
                  >
                    {languages.map(item => <option key={item.key} value={item.key}>{item.label}</option>)}
                  </select>
                </li>
              </ul>
            </div>
            <div>
              <ul>
                <li>Company</li>
                <li><Link to="/about-us">About us</Link></li>
              </ul>
            </div>
            <div>
              <ul>
                <li>Guests</li>
                <li><Link to="/contact-us">Contact Us</Link></li>
                <li><Link to="/faq">FAQ</Link></li>
              </ul>
            </div>
            <div>
              <ul>
                <li>Privacy</li>
                <li><Link to="/terms-of-service">Terms of Service</Link></li>
                <li><Link to="/privacy-policy">Privacy Policy</Link></li>
              </ul>
            </div>
          </div>
          <div>
            <Link to="https://tipocosa.it/" target="_blank">
              <img src={footerLogo} alt="Footer logo" height="40px" width={"40px"} />
            </Link>
          </div>
        </div>
        <div className={css.bottomContents}>
          <span>© 2024 DART. All rights reserved.</span>
        </div>
      </Container>
    </div>
  );
};

export default Footer;
