import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";


let axiosConfig = {
    headers: {
        "Content-Type": "application/json",
        // "credentials": 'include',
        // "Acess-Control-Allow-Origin":`${server}`
    },
    withCredentials: true,
};
const intialValue = {
    user: null,
    isAuth: false,
    isVerifyEmailSend: false,
    isResetPassword: false,
    isImageUploaded: false,
    status: 'idle',
    error: null,
    profile: null,
    bookingStatus: 'idle',
    bookingError: null,
}
export const authSignupUser = createAsyncThunk(
    'auth/signupUser',
    async (userData, { rejectWithValue }) => {
        const data = JSON.stringify(userData);
        try {
            const res = await axios.post(`${process.env.REACT_APP_BACKEND_URI}/auth/signup`, data, axiosConfig)
            if (res.status === 200)
                return res.data;
        } catch (e) {
            return rejectWithValue(e.response?.data);
        }
    }
)

export const uploadAwsImage = createAsyncThunk(
    'upload/image',
    async (file) => {
        try {
            const formData = new FormData();
            formData.append('file', file);
            const res = await axios.post(`${process.env.REACT_APP_BACKEND_URI}/auth/upload-file-aws`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            return res.data;
        } catch (e) {
            console.error('Error uploading image:', e);
            throw e;
        }
    }
)

export const uploadAwsImages = createAsyncThunk(
    'upload/images',
    async (files) => {
        try {
            const formData = new FormData();
            for (const index in files) {
                formData.append('files', files[index])
            }
            const res = await axios.post(`${process.env.REACT_APP_BACKEND_URI}/auth/upload-files-aws`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            return res.data;
        } catch (e) {
            console.error('Error uploading image:', e);
            throw e;
        }
    }
)

export const authLoginUser = createAsyncThunk(
    'auth/LoginUser',
    async (userData, { rejectWithValue }) => {
        const data = JSON.stringify(userData);
        try {
            const res = await axios.post(`${process.env.REACT_APP_BACKEND_URI}/auth/login`, data, axiosConfig);
            if (res.status === 200)
                return res.data;

        } catch (e) {
            return rejectWithValue(e.response?.data);
        }
    }
)
export const authLogoutUser = createAsyncThunk(
    'auth/LogoutUser',
    async () => {
        try {
            const res = await axios.get(`${process.env.REACT_APP_BACKEND_URI}/auth/logout`, axiosConfig);
            return res.data.ok;
        } catch (e) {
            return (e.message);
        }
    }
)
export const loadUserProfile = createAsyncThunk(
    'profile/loadUserProfile',
    async () => {
        try {
            const res = await axios.get(`${process.env.REACT_APP_BACKEND_URI}/auth/user/fetch-user-profile`, axiosConfig)
            if (res.status === 200) {
                return res.data?.data
            }
            else {
                throw new Error("Failed!")
            }
        } catch (e) {
            return e.response?.data?.message
        }
    }

)
export const createUserProfile = createAsyncThunk(
    'profile/createUserProfile',
    async (data) => {
        try {
            const userData = JSON.stringify(data)
            const res = await axios.post(`${process.env.REACT_APP_BACKEND_URI}/auth/user/create-user-profile`, userData, axiosConfig)
            if (res.status === 200) {
                return res.data?.data
            }
            else {
                throw new Error("Failed!")
            }
        } catch (e) {
            return e.response?.data?.message
        }
    }

)
export const updateUserProfile = createAsyncThunk(
    'profile/updateUserProfile',
    async (data) => {
        try {
            const { id, ...rest } = data
            const userData = JSON.stringify(rest)
            const res = await axios.put(`${process.env.REACT_APP_BACKEND_URI}/auth/user/update-user-profile/${id}`, userData, axiosConfig)
            if (res.status === 200) {
                return res.data?.data
            }
            else {
                throw new Error("Failed!")
            }
        } catch (e) {
            return e.response?.data?.message
        }
    }

)
export const loadUser = createAsyncThunk(
    'auth/LoadIntialData',
    async () => {
        try {
            const res = await axios.get(`${process.env.REACT_APP_BACKEND_URI}/auth/get-user`, axiosConfig);
            return res.data.payload;
        }
        catch (e) {
            return e.message
        }
    }
)
export const deleteImage = createAsyncThunk(
    'auth/deleteImage',
    async (data) => {
        try {
            const { table, name, key } = data;
            const res = await axios.delete(`${process.env.REACT_APP_BACKEND_URI}/auth/delete-image/${table}/${name}?key=${key}`);
            return res?.data;
        } catch (e) {
            return e?.response?.message;
        }
    }
)


export const getUserDetails = createAsyncThunk(
    'auth/getUserDetails',
    async () => {
        try {
            const { data = {} } = await axios.get(`${process.env.REACT_APP_BACKEND_URI}/users`);
            return data;
        }
        catch (e) {
            throw new Error('Error During fetching users')
        }
    }
)

export const verifyEmail = createAsyncThunk(
    'auth/verify-email',
    async () => {
        try {
            const res = await axios.get(`${process.env.REACT_APP_BACKEND_URI}/auth/verify-email`, axiosConfig);
            return res;
        } catch (e) {
            return e;
        }
    }
)
export const resetPassword = createAsyncThunk(
    'auth/reset-password',
    async (userData, { rejectWithValue }) => {
        try {
            const res = await axios.post(`${process.env.REACT_APP_BACKEND_URI}/auth/reset-password`, { email: userData }, axiosConfig);
            return res;

        } catch (e) {
            console.log(e)
            return rejectWithValue(e.message);
        }
    }
)
export const deleteCurrentUser = createAsyncThunk(
    'auth/delete-currentUser',
    async () => {
        try {
            const res = await axios.delete(`${process.env.REACT_APP_BACKEND_URI}/auth/delete-current-user`, axiosConfig)
            return res;

        } catch (e) {
            return e;
        }
    }
)
export const updateProfile = createAsyncThunk(
    'auth/updateProfile',
    async (data, { rejectWithValue }) => {
        try {
            const res = await axios.put(`${process.env.REACT_APP_BACKEND_URI}/auth/update-profile`, data, axiosConfig);
            return res.data;
        } catch (e) {
            console.log(e);
            return rejectWithValue(e.message);
        }
    }
)
export const updateEmail = createAsyncThunk(
    'auth/updateEmail',
    async (data) => {
        try {
            const res = await axios.put(`${process.env.REACT_APP_BACKEND_URI}/auth/update-email`, data, axiosConfig);
            // if (res.status !== 200)
            return res
            // return null
        } catch (e) {
            return e?.response
        }
    }
)
export const postBookings = createAsyncThunk(
    'bookings/postBookings',
    async ({ table, id, data }) => {
        try {
            const userData = JSON.stringify(data)
            const res = await axios.post(`${process.env.REACT_APP_BACKEND_URI}/bookings/request/${table}/${id}`, userData, axiosConfig)
            return res;
        } catch (e) {
            console.log('e', e)
            return e?.response
        }
    }
)

const authSlice = createSlice({
    name: 'auth',
    initialState: intialValue,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(deleteImage.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(deleteImage.fulfilled, (state, action) => {
                state.status = 'fulfilled';
                if (action?.payload?.status === 200)
                    state.user.profile = null;
                state.error = null;
            })
            .addCase(deleteImage.rejected, (state, action) => {
                state.status = "error";
                state.error = action.error.message;
            })
            .addCase(updateEmail.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(updateEmail.fulfilled, (state, action) => {
                state.status = 'fulfilled';
                if (action?.payload?.status === 200) {
                    state.user.isverified = false;
                    state.user.email = action.payload?.data?.data
                }
                state.error = null;
            })
            .addCase(updateEmail.rejected, (state, action) => {
                state.status = "error";
                state.error = action.error.message;
            })
            .addCase(updateProfile.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(updateProfile.fulfilled, (state, action) => {
                state.user = action.payload?.data
                state.error = null;
            })
            .addCase(updateProfile.rejected, (state, action) => {
                state.status = "error";
                state.error = action.error.message;
            })
            .addCase(updateUserProfile.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(updateUserProfile.fulfilled, (state, action) => {
                state.profile = action.payload
                state.error = null;
            })
            .addCase(updateUserProfile.rejected, (state, action) => {
                state.status = "error";
                state.error = action.error.message;
            })
            .addCase(createUserProfile.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(createUserProfile.fulfilled, (state, action) => {
                state.profile = action.payload
                state.error = null;
            })
            .addCase(createUserProfile.rejected, (state, action) => {
                state.status = "error";
                state.error = action.error.message;
            })
            // .addCase(uploadAwsImages.pending, (state) => {
            //     state.status = 'loading';
            //     state.error = null;
            // })
            // .addCase(uploadAwsImages.fulfilled, (state, action) => {
            //     state.isImageUploaded = true;
            //     state.error = null;
            // })
            // .addCase(uploadAwsImages.rejected, (state, action) => {
            //     state.status = "error";
            //     state.error = action.error.message;
            // })
            // .addCase(uploadAwsImage.pending, (state) => {
            //     state.status = 'loading';
            //     state.error = null;
            // })
            // .addCase(uploadAwsImage.fulfilled, (state, action) => {
            //     state.isImageUploaded = true;
            //     state.error = null;
            // })
            // .addCase(uploadAwsImage.rejected, (state, action) => {
            //     state.status = "error";
            //     state.error = action.error.message;
            // })
            .addCase(authSignupUser.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(authSignupUser.fulfilled, (state, action) => {
                state.status = 'fulfilled';
                state.user = action.payload?.data;
                state.isAuth = true;
                state.error = null;
            })
            .addCase(authSignupUser.rejected, (state, action) => {
                state.status = "error";
                state.error = action.error.message;
            })
            .addCase(authLoginUser.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(authLoginUser.fulfilled, (state, action) => {
                state.status = 'fulfilled';
                state.user = action.payload?.data;
                state.isAuth = true;
                state.error = null;
            })
            .addCase(authLoginUser.rejected, (state, action) => {
                state.status = "error";
                state.error = action.payload?.message;
            })
            .addCase(loadUserProfile.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(loadUserProfile.fulfilled, (state, action) => {
                state.status = 'fulfilled';
                state.profile = action.payload
                state.error = null;
            })
            .addCase(loadUserProfile.rejected, (state, action) => {
                state.status = "error";
                state.error = action.error;
            })
            .addCase(authLogoutUser.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(authLogoutUser.fulfilled, (state) => {
                state.status = 'fulfilled';
                state.user = null;
                state.profile = null;
                state.isAuth = false;
                state.error = null;
            })
            .addCase(authLogoutUser.rejected, (state, action) => {
                state.status = "error";
                state.error = action.error.message;
            })
            .addCase(loadUser.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(loadUser.fulfilled, (state, action) => {
                if (action?.payload?.id) {
                    state.user = action.payload;
                    state.isAuth = true;
                    state.status = 'fulfilled';
                    state.error = null;
                }
                else {
                    state.status = 'failed';
                    state.error = action.payload;
                }

            })
            .addCase(loadUser.rejected, (state, action) => {
                state.status = 'error';
                state.error = action.error.message;
            })
            .addCase(verifyEmail.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(verifyEmail.fulfilled, (state, action) => {
                state.status = 'fulfilled';
                state.isVerifyEmailSend = true;
                state.error = null;
            })
            .addCase(verifyEmail.rejected, (state, action) => {
                state.status = 'error';
                state.error = action.error.message;
            })
            .addCase(resetPassword.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(resetPassword.fulfilled, (state) => {
                state.status = 'fulfilled';
                state.isResetPassword = true;
                state.error = null;
            })
            .addCase(resetPassword.rejected, (state, action) => {
                state.status = 'error';
                state.error = action.error.message;
            })
            .addCase(deleteCurrentUser.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(deleteCurrentUser.fulfilled, (state) => {
                state.status = 'fulfilled';
                state.error = null;
            })
            .addCase(deleteCurrentUser.rejected, (state, action) => {
                state.status = 'error';
                state.error = action.error.message;
            })
            .addCase(postBookings.pending, (state) => {
                state.bookingStatus = 'pending';
                state.bookingError = null;
            })
            .addCase(postBookings.fulfilled, (state, action) => {
                state.user.booking = action.payload.data.data.booking;
                state.user.credits_used = action.payload.data.data.credits_used;
                state.bookingStatus = 'fulfilled';
                state.bookingError = null;

            })
            .addCase(postBookings.rejected, (state, action) => {
                state.bookingStatus = 'rejected';
                state.bookingError = action.payload;
            })

    }


})

export default authSlice.reducer