import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

let axiosConfig = {
  headers: {
    "Content-Type": "application/json",
    // "credentials": 'include',
    // "Acess-Control-Allow-Origin":`${server}`
  },
  withCredentials: true,
};
const intialValue = {
  allListing: [],
  currentListing: {},
  status: 'idle',
  isSearching: false,
  error: null,
}
export const sendAgentinvitaiton = createAsyncThunk(
  'listing/send-invite-agent',
  async (userData) => {
    try {
      const data = JSON.stringify(userData);
      const res = await axios.post(`${process.env.REACT_APP_BACKEND_URI}/auth/artist-agent-email`, data, axiosConfig);
      return res;
    } catch (e) {
      return e?.response
    }
  }
)
export const sendArtistinvitaiton = createAsyncThunk(
  'listing/send-invite-artist',
  async (userData) => {
    try {
      const data = JSON.stringify(userData);
      const res = await axios.post(`${process.env.REACT_APP_BACKEND_URI}/auth/agent-artist-email`, data, axiosConfig);
      return res;
    } catch (e) {
      return e?.response
    }
  }
)
export const searchQueryByType = createAsyncThunk(
  'listing/search-keyword',
  async (data) => {
    try {
      const { type, keyword } = data;
      const res = await axios.get(`${process.env.REACT_APP_BACKEND_URI}/users/${type}/?keyword=${keyword}`, axiosConfig)
      return res?.data;
    } catch (e) {
      console.log(e);
    }
  }
)
export const deleteListing = createAsyncThunk(
  'listing/deleteListing',
  async (id) => {
    try {
      const res = await axios.delete(`${process.env.REACT_APP_BACKEND_URI}/auth/delete-listing/${id}`, axiosConfig);
      return res.data;
    } catch (e) {
      console.log(e)
    }
  }
)
export const fetchCurrentListing = createAsyncThunk(
  'listing/fetchCurrentListing',
  async (data) => {
    const { id, table } = data
    try {
      const res = await axios.get(`${process.env.REACT_APP_BACKEND_URI}/listings/${table}?id=${id}`, axiosConfig);
      return res.data;
    } catch (e) {
      console.log(e)
    }
  }
)
export const fetchCurrentUserAllListing = createAsyncThunk(
  'auth/fetchCurrentUserAllListing',
  async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_BACKEND_URI}/user-listings`, axiosConfig)
      return res.data;
    } catch (e) {
      throw new Error(e)
    }
  }
)
export const createListing = createAsyncThunk(
  'auth/createListing',
  async (userData, { rejectWithValue }) => {
    const { id, ...rest } = userData;
    const data = JSON.stringify(rest);
    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URI}/auth/create-listing`, data, axiosConfig)
      return response.data.payload || response.data;
    } catch (e) {
      return rejectWithValue(e.message);
    }
  }
)
export const updateListing = createAsyncThunk(
  'auth/updateListing',
  async (userData) => {
    const { id, ...rest } = userData;
    const data = JSON.stringify(rest);
    try {
      const response = await axios.put(`${process.env.REACT_APP_BACKEND_URI}/auth/update-listing/${id}`, data, axiosConfig)
      return response.data.payload || response.data;
    } catch (e) {
      return (e.message);
    }
  }
)
export const deleteListingImages = createAsyncThunk(
  'auth/deleteListingImage',
  async (data) => {
    const { name, key, listingId } = data
    try {
      const res = await axios.delete(`${process.env.REACT_APP_BACKEND_URI}/auth/delete-file-aws/${listingId}/${name}?key=${key}`, axiosConfig);
      return res;
    } catch (e) {
      console.log(e)
      return e?.response;
    }
  }
)

const listingSlice = createSlice({
  name: "listing",
  initialState: intialValue,
  reducers: {
    removeData: (state) => {
      state.currentListing = {}
    }
  },
  extraReducers: (builder) => {
    builder
      // searchQueryByType
      .addCase(deleteListingImages.pending, (state) => {
        state.status = 'pending';
        state.error = null;
      })
      .addCase(deleteListingImages.fulfilled, (state, action) => {
        state.status = 'fulfilled';
        state.currentListing = action.payload?.data
        state.error = null;
      })
      .addCase(deleteListingImages.rejected, (state, action) => {
        state.status = 'rejected';
        state.error = action.payload?.message;
      })
      .addCase(searchQueryByType.pending, (state) => {
        state.status = 'pending';
        state.error = null;
      })
      .addCase(searchQueryByType.fulfilled, (state, action) => {
        state.isSearching = true;
        state.status = 'fulfilled';
        state.error = null;
      })
      .addCase(searchQueryByType.rejected, (state, action) => {
        state.status = 'rejected';
        state.error = action.payload?.message;
      })
      .addCase(deleteListing.pending, (state) => {
        state.status = 'pending';
        state.error = null;
      })
      .addCase(deleteListing.fulfilled, (state, action) => {
        state.allListing = null;
        state.currentListing = null;
        state.status = 'fulfilled';
        state.error = null;
      })
      .addCase(deleteListing.rejected, (state, action) => {
        state.status = 'rejected';
        state.error = action.payload;
      })
      .addCase(fetchCurrentListing.pending, (state) => {
        state.status = 'pending';
        state.error = null;
      })
      .addCase(fetchCurrentListing.fulfilled, (state, action) => {
        state.currentListing = action.payload?.data;
        state.status = 'fulfilled';
        state.error = null;
      })
      .addCase(fetchCurrentListing.rejected, (state, action) => {
        state.status = 'rejected';
        state.error = action.payload;
      })
      .addCase(fetchCurrentUserAllListing.pending, (state) => {
        state.status = 'pending';
        state.error = null;
      })
      .addCase(fetchCurrentUserAllListing.fulfilled, (state, action) => {
        state.allListing = action.payload?.data;
        state.status = 'fulfilled';
        state.error = null;
      })
      .addCase(fetchCurrentUserAllListing.rejected, (state, action) => {
        state.status = 'rejected';
        state.error = action.payload;
      })
      .addCase(createListing.pending, (state) => {
        state.status = 'pending';
        state.error = null;
      })
      .addCase(createListing.fulfilled, (state, action) => {
        state.status = 'fulfilled';
        state.currentListing = action.payload;
        state.error = null;
      })
      .addCase(createListing.rejected, (state, action) => {
        state.status = 'error';
        state.error = action.error;
      })
      .addCase(updateListing.pending, (state) => {
        state.status = 'pending';
        state.error = null;
      })
      .addCase(updateListing.fulfilled, (state, action) => {
        state.status = 'fulfilled';
        state.currentListing = action.payload;
        state.error = null;
      })
      .addCase(updateListing.rejected, (state, action) => {
        state.status = 'error';
        state.error = action.error;
      })
  }
})
export const { removeData } = listingSlice.actions;
export default listingSlice.reducer;