import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import LandingPage from './containers/LandingPage/LandingPage';
import { useEffect } from 'react';

function App() {

  useEffect(() => {
    const language = localStorage.getItem("language")
    if (!language)
      localStorage.setItem("language", "it")
  }, [])

  return (
    <div className="App">
      <LandingPage />
      <ToastContainer />
    </div>
  );
}

export default App;
