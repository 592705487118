import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import IconCollection from "../IconCollection/IconCollection";
import css from "./VerifyModal.module.scss";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { verifyEmail } from "../../redux/reducers/authSlice";
import { toast } from "react-toastify";

const VerifyModal = ({ closeModal, email, name, isSignupPage = false }) => {
  //Modal
  const [isClose, setIsClose] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    if (closeModal) {
      setIsClose(true);
    }
  }, [closeModal]);
  const isMobile = typeof window !== "undefined" && window.innerWidth < 767;
  const customStyles = {
    content: {
      inset: isMobile ? "0 0 0 0" : "50% auto auto 50%",
      marginRight: "-50%",
      width: isMobile ? "100%" : "50%",
      transform: isMobile ? "unset" : "translate(-50%, -50%)",
      padding: isMobile ? "48px 24px" : "48px 64px 66px",
      maxWidth: isMobile ? "100%" : "480px",
    },
  };
  const handleModalClose = () => {
    setIsClose(false);
    if (isSignupPage) navigate('/')
  }
  const handleResendEmail = async (e) => {
    e.preventDefault();
    try {
      // Wrap the dispatch call in toast.promise
      await toast.promise(
        dispatch(verifyEmail()),
        {
          pending: 'Resending email...', // Optional: Message shown while promise is pending
          success: 'Email resent successfully!', // Message shown on success
          error: 'Failed to resend email. Please try again.', // Message shown on error
          position: "top-right",
          autoClose: 3000,
        }
      );
    } catch (error) {
      console.error('Error while resending email:', error);
    }
  }
  const handleChangeEmail = async (e) => {
    e.preventDefault();
    setIsClose(false);
    navigate('/update-profile')
  }
  return (
    <Modal
      isOpen={isClose}
      onRequestClose={() => { }}
      ariaHideApp={false}
      style={customStyles}
      contentLabel="Example Modal"
    >
      <div className={css.modalWrapper}>
        <span className={css.closeIcon} onClick={handleModalClose}>
          <IconCollection name="CLOSE_ICON" />
        </span>
        <IconCollection name="EMAIL_ICON" />
        <h1>{name} check your email inbox to verify your email address</h1>
        <p>
          Thanks for signing up! There's one quick step left. Please verify your
          email address so we can contact you.
        </p>
        <br/>
        <p> Click the link we sent to
          <span className={css.emailText}>-{email}</span></p>
        <div className={css.bottomContent}>
          <p>
            Didn't get the email? <button onClick={handleResendEmail}>Resend email.</button>
          </p>
          <p>
            Is there an error in your email? <button onClick={handleChangeEmail}>Fix it.</button>
          </p>
        </div>
      </div>
    </Modal>
  );
};

export default VerifyModal;
