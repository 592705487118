import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

let axiosConfig = {
  headers: {
    "Content-Type": "application/json",
    // "credentials": 'include',
    // "Acess-Control-Allow-Origin":`${server}`
  },
  withCredentials: true,
};
const intialValue = {
  tenVenueListings: [],
  venueListingsStatus: 'idle',
  venueListingsError: null,
  tenShowListings: [],
  showListingsStatus: 'idle',
  showListingsError: null,
}

export const getTenVenueListings = createAsyncThunk('search/venueListings', async () => {
  try {
    const res = await axios.get(`${process.env.REACT_APP_BACKEND_URI}/listings/search/venues`, axiosConfig);
    return res?.data?.data?.data;
  } catch (e) {
    console.log('e', e)
    return e?.response
  }
})

export const getTenShowListings = createAsyncThunk('search/showListings', async () => {
  try {
    const res = await axios.get(`${process.env.REACT_APP_BACKEND_URI}/listings/search/shows`, axiosConfig);
    return res?.data?.data?.data;
  } catch (e) {
    console.log('e', e)
    return e?.response
  }
})
export const sendInquiryMail = createAsyncThunk('contact-us/send-enquiry', async (data) => {
  try {
    const res = await axios.post(`${process.env.REACT_APP_BACKEND_URI}/send-inquiry`, data, axiosConfig);
    return res.data;
  } catch (e) {
    return e?.response;
  }
})

const landingSlice = createSlice({
  name: "landing",
  initialState: intialValue,
  extraReducers: (builder) => {
    builder
      .addCase(getTenVenueListings.pending, (state) => {
        state.venueListingsStatus = 'pending';
        state.venueListingsError = null;
      })
      .addCase(getTenVenueListings.fulfilled, (state, action) => {
        state.tenVenueListings = action.payload;
        state.venueListingsStatus = 'fulfilled';
        state.venueListingsError = null;
      })
      .addCase(getTenVenueListings.rejected, (state, action) => {
        state.venueListingsStatus = 'rejected';
        state.venueListingsError = action.payload;
      })
      .addCase(getTenShowListings.pending, (state) => {
        state.showListingsStatus = 'pending';
        state.showListingsError = null;
      })
      .addCase(getTenShowListings.fulfilled, (state, action) => {
        state.tenShowListings = action.payload;
        state.showListingsStatus = 'fulfilled';
        state.showListingsError = null;
      })
      .addCase(getTenShowListings.rejected, (state, action) => {
        state.showListingsStatus = 'rejected';
        state.showListingsError = action.payload;
      })
  }
})

export default landingSlice.reducer;