
import React from 'react';
// import serviceImage from '../../assets/Images/servicesSection.png'

import css from './ServicesSection.module.scss'
import Container from '../Container/Container';
import IconCollection from '../IconCollection/IconCollection';
// import IconCollection from '../IconCollection/IconCollection';

const ServiceSection = () => {
    return (
        <Container>
            <div className={css.servicesSection}>
                <h1 className={css.serviceTitle}>Come funziona</h1>
                <h6 className={css.serviceDescription}>Mettiamo a disposizione degli artisti, delle agenzie di spettacolo e dei gestori di teatri e rassegne tutti gli strumenti per semplificare il processo di ricerca e booking</h6>
                <div className={css.servicesCards}>
                    <div className={css.serviceCard}>
                        <IconCollection name="SEARCH_ICON" />
                        <div className={css.cardContents}>
                            <h3 className={css.cardTitle}>Scopri</h3>
                            <div className={css.tickContent}>
                                <p>Scopri nuovi show e venue in tutta Italia esplorando il catalogo grazie ai numerosi filtri di ricerca.</p>
                            </div>
                        </div>
                    </div>
                    <div className={css.serviceCard}>
                        <IconCollection name="AVATAR_ICON" />
                        <div className={css.cardContents}>
                            <h3 className={css.cardTitle}>Collegati</h3>
                            <div className={css.tickContent}>
                                <p>Collegati direttamente con gli artisti, le agenzie e le venue che preferisci e accedi a tutte le loro informazioni.</p>
                            </div>
                        </div>
                    </div>
                    <div className={css.serviceCard}>
                        <IconCollection name="DOCUMENT_ICON" />
                        <div className={css.cardContents}>
                            <h3 className={css.cardTitle}>Crea</h3>
                            <div className={css.tickContent}>
                                <p>Crea la tua pagina personalizzata contenente tutte le informazioni tecniche utili e i tuoi contatti.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Container>
    );
};

export default ServiceSection;