import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

let axiosConfig = {
    headers: {
        "Content-Type": "application/json",
        // "credentials": 'include',
        // "Acess-Control-Allow-Origin":`${server}`
    },
    withCredentials: true,
};
const intialValue = {
    status: 'idle',
    error: null,
    bookingData: {}
}

export const getBooking = createAsyncThunk(
    'bookings/getBooking',
    async (id) => {
        try {
            const res = await axios.get(`${process.env.REACT_APP_BACKEND_URI}/bookings/get-user-bookings?id=${id}`, axiosConfig);
            return res?.data;
        } catch (e) {
            console.log('e', e)
            return e?.response
        }
    }
)

export const updateBookingStatus = createAsyncThunk(
    "bookings/updateBooking",
    async ({ id, state, values = {} }) => {
        try {
            const res = await axios.put(`${process.env.REACT_APP_BACKEND_URI}/bookings/request/${id}/${state}`, values, axiosConfig);
            return res?.data;
        } catch (e) {
            console.log("e", e);
            return e?.response;
        }
    });

export const cancelBooking = createAsyncThunk(
    "bookings/cancelBooking",
    async ({ id }) => {
        try {
            const res = await axios.patch(`${process.env.REACT_APP_BACKEND_URI}/admin/cancel-booking/${id}`, {}, axiosConfig);
            return res;
        } catch (e) {
            console.log("e", e);
            return e?.response;
        }
    });

export const cancelBookingByAuthor = createAsyncThunk(
    "bookings/cancelBookingByAuthor",
    async ({ id, values = {} }) => {
        try {
            const res = await axios.put(`${process.env.REACT_APP_BACKEND_URI}/bookings/cancelled-by-author/${id}`, values, axiosConfig);
            return res?.data;
        } catch (e) {
            console.log("e", e);
            return e?.response;
        }
    });

const bookingSlice = createSlice({
    name: "booking",
    initialState: intialValue,
    extraReducers: (builder) => {
        builder
            .addCase(getBooking.pending, (state) => {
                state.status = 'pending';
                state.error = null;
            })
            .addCase(getBooking.fulfilled, (state, action) => {
                state.status = 'fulfilled';
                state.bookingData = action.payload?.data;
                state.error = null;
            })
            .addCase(getBooking.rejected, (state, action) => {
                state.status = 'rejected';
                state.error = action.payload;
            })
            .addCase(updateBookingStatus.pending, (state) => {
                state.status = 'pending';
                state.error = null;
            })
            .addCase(updateBookingStatus.fulfilled, (state, action) => {
                state.status = 'fulfilled';
                state.bookingData.status = action.payload?.data.status;
                state.bookingData.states = action.payload?.data.states;
            })
            .addCase(updateBookingStatus.rejected, (state, action) => {
                state.status = 'rejected';
                state.bookingData.status = action.payload?.data.status;
                state.error = action.payload;
            })
            .addCase(cancelBookingByAuthor.pending, (state) => {
                state.status = 'pending';
                state.error = null;
            })
            .addCase(cancelBookingByAuthor.fulfilled, (state, action) => {
                state.status = 'fulfilled';
                state.bookingData.status = action.payload?.data.status;
                state.bookingData.states = action.payload?.data.states;
            })
            .addCase(cancelBookingByAuthor.rejected, (state, action) => {
                state.status = 'rejected';
                state.bookingData.status = action.payload?.data.status;
                state.error = action.payload;
            })
    }
})

export default bookingSlice.reducer;