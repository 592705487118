// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Container_container__MM274 {
  width: 100%;
  max-width: 1320px;
  padding: 0 40px;
  margin: 0 auto;
}
@media (max-width: 1200px) {
  .Container_container__MM274 {
    padding: 0 20px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Container/Container.module.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,iBAAA;EACA,eAAA;EACA,cAAA;AACJ;AAAI;EALJ;IAMQ,eAAA;EAGN;AACF","sourcesContent":[".container{\n    width: 100%;\n    max-width: 1320px;\n    padding: 0 40px;\n    margin: 0 auto;\n    @media (max-width: 1200px) {\n        padding: 0 20px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Container_container__MM274`
};
export default ___CSS_LOADER_EXPORT___;
