import { configureStore } from '@reduxjs/toolkit';
import authReducer from './reducers/authSlice'
import listingReducers from './reducers/listingSlice';
import searchReducers from './reducers/searchSlice';
import inboxReducers from './reducers/inboxSlice';
import bookingReducers from './reducers/bookingSlice';
import { persistStore, getStoredState, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import adminReducers from './reducers/adminSlice';
import landingReducers from './reducers/landingSlice';
import subscriptionReducers from './reducers/subscriptionSlice';
import hardSet from 'redux-persist/lib/stateReconciler/hardSet';
// import { api } from './reducers/api';

const authPersistConfig = {
    key: 'root',
    storage,
    stateReconciler: hardSet,
    whitelist: ['isAuth', 'user'] // List the fields you want to persist
};
const persistedAuthReducer = persistReducer(authPersistConfig, authReducer);

export const store = configureStore({
    reducer: {
        user: persistedAuthReducer,
        listing: listingReducers,
        search: searchReducers,
        inbox: inboxReducers,
        booking: bookingReducers,
        admin: adminReducers,
        landing: landingReducers,
        subscription: subscriptionReducers
    },
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
            }
        })
});
export function crossBrowserListener(store, persistConfig) {
    return async function () {
        let state = await getStoredState(persistConfig)

        store.dispatch({
            type: REHYDRATE,
            key: persistConfig.key,
            payload: state,
        })
    }
}
window.addEventListener('storage', crossBrowserListener(store, authPersistConfig))
export const persistor = persistStore(store);
export const purgeData = async () => {    // dispatch(loadUser()); //change it on refresh

    await persistor.purge();
};