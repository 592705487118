const language = localStorage.getItem('language');
const data = require(`../../transalation/${language ?? "it"}.json`)

const FormatText = (id,value) => {
if(data[id]?.includes("{value}")){
  const newData=data[id].replace("{value}", value);
  return newData ?? `${id}`;
}else
  return `${data[id]}` ?? `${id}`;
}

export default FormatText