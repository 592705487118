import classNames from 'classnames';
import React from 'react';
import css from './Container.module.scss'

const Container = ({children, className}) => {
    return (
        <div className={classNames(css.container,className)}>
            {children}
        </div>
    );
};

export default Container;