// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.VerifyModal_modalWrapper__8\\+s-e {
  position: relative;
}
.VerifyModal_modalWrapper__8\\+s-e .VerifyModal_closeIcon__RkX5x {
  position: absolute;
  right: -42px;
  top: -32px;
  height: 24px;
  width: 24px;
  cursor: pointer;
}
@media (max-width: 767px) {
  .VerifyModal_modalWrapper__8\\+s-e .VerifyModal_closeIcon__RkX5x {
    right: -10px;
  }
}
.VerifyModal_modalWrapper__8\\+s-e .VerifyModal_closeIcon__RkX5x svg {
  width: 100%;
  height: 100%;
}
.VerifyModal_modalWrapper__8\\+s-e h1 {
  color: #4a4a4f;
  margin: 24px 0;
}
.VerifyModal_modalWrapper__8\\+s-e p {
  color: #4a4a4f;
  font-size: 20px;
  line-height: 32px;
  font-weight: 500;
}
.VerifyModal_modalWrapper__8\\+s-e p .VerifyModal_emailText__fowVN {
  font-weight: 700;
}

.VerifyModal_bottomContent__U8kf9 {
  margin-top: 96px;
}
.VerifyModal_bottomContent__U8kf9 p {
  font-size: 14px;
  color: #000;
  font-weight: 300;
  line-height: 20px;
  margin-top: 12px;
}
.VerifyModal_bottomContent__U8kf9 button {
  display: inline;
  width: -moz-fit-content;
  width: fit-content;
  padding: 0 12px;
  background: #3d70a1;
  font-size: 13px;
  line-height: 20px;
  font-weight: 400;
  margin-left: 4px;
}
.VerifyModal_bottomContent__U8kf9 button:focus, .VerifyModal_bottomContent__U8kf9 button:hover {
  border: nones;
}`, "",{"version":3,"sources":["webpack://./src/components/VerifyModal/VerifyModal.module.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;AACJ;AAAI;EACE,kBAAA;EACA,YAAA;EACA,UAAA;EACA,YAAA;EACA,WAAA;EACA,eAAA;AAEN;AADM;EAPF;IAQI,YAAA;EAIN;AACF;AAHM;EACE,WAAA;EACA,YAAA;AAKR;AAFI;EACE,cAAA;EACA,cAAA;AAIN;AAFI;EACE,cAAA;EACA,eAAA;EACA,iBAAA;EACA,gBAAA;AAIN;AAHM;EACE,gBAAA;AAKR;;AADE;EACE,gBAAA;AAIJ;AAHI;EACE,eAAA;EACA,WAAA;EACA,gBAAA;EACA,iBAAA;EACA,gBAAA;AAKN;AAHI;EACE,eAAA;EACA,uBAAA;EAAA,kBAAA;EACA,eAAA;EACA,mBAAA;EACA,eAAA;EACA,iBAAA;EACA,gBAAA;EACA,gBAAA;AAKN;AAJM;EACE,aAAA;AAMR","sourcesContent":[".modalWrapper{\n    position: relative;\n    & .closeIcon{\n      position: absolute;\n      right: -42px;\n      top: -32px;\n      height: 24px;\n      width: 24px;\n      cursor: pointer;\n      @media (max-width: 767px) {\n        right: -10px;\n      }\n      & svg{\n        width: 100%;\n        height: 100%;\n      }\n    }\n    & h1{\n      color: #4a4a4f;\n      margin: 24px 0;\n    }\n    & p{\n      color: #4a4a4f;\n      font-size: 20px;\n      line-height: 32px;\n      font-weight: 500;\n      & .emailText{\n        font-weight: 700;\n      }\n    }\n  }\n  .bottomContent{\n    margin-top: 96px;\n    & p{\n      font-size: 14px;\n      color: #000;\n      font-weight: 300;\n      line-height: 20px;\n      margin-top: 12px;\n    }\n    & button{\n      display: inline;\n      width: fit-content;\n      padding: 0 12px;\n      background: #3d70a1;\n      font-size: 13px;\n      line-height: 20px;\n      font-weight: 400;\n      margin-left: 4px;\n      &:focus, &:hover{\n        border: nones;\n      }\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalWrapper": `VerifyModal_modalWrapper__8+s-e`,
	"closeIcon": `VerifyModal_closeIcon__RkX5x`,
	"emailText": `VerifyModal_emailText__fowVN`,
	"bottomContent": `VerifyModal_bottomContent__U8kf9`
};
export default ___CSS_LOADER_EXPORT___;
