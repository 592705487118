import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

let axiosConfig = {
    headers: {
        "Content-Type": "application/json",
        // "credentials": 'include',
        // "Acess-Control-Allow-Origin":`${server}`
    },
    withCredentials: true,
};
const intialValue = {
    userBookings: [],
    total: null,
    status: 'idle',
    error: null,
}

export const getUserBookings = createAsyncThunk(
    'bookings/user-bookings',
    async (type) => {
        try {
            const res = await axios.get(`${process.env.REACT_APP_BACKEND_URI}/bookings/get-user-bookings?type=${type}`, axiosConfig);
            return res?.data;
        } catch (e) {
            console.log('e', e)
            return e?.response
        }
    }
)

const inboxSlice = createSlice({
    name: "inbox",
    initialState: intialValue,
    extraReducers: (builder) => {
        builder
            .addCase(getUserBookings.pending, (state) => {
                state.status = 'pending';
                state.error = null;
            })
            .addCase(getUserBookings.fulfilled, (state, action) => {
                state.userBookings = action.payload.data.data;
                state.total = action.payload.data.total;
                state.status = 'fulfilled';
                state.error = null;
            })
            .addCase(getUserBookings.rejected, (state, action) => {
                state.status = 'rejected';
                state.error = action.payload;
            })
    }
})

export default inboxSlice.reducer;