import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";


let axiosConfig = {
  headers: {
    "Content-Type": "application/json",
    // "credentials": 'include',
    // "Acess-Control-Allow-Origin":`${server}`
  },
  withCredentials: true,
};

export const getAllUsers = createAsyncThunk(
  'admin/getAllusers',
  async (props) => {
    const { page = "", usertype = "", keyword = "", id = "" } = props;
    try {
      if (!!id) {
        const res = await axios.get(`${process.env.REACT_APP_BACKEND_URI}/admin/users?id=${id}`, axiosConfig);
        return res.data;
      } else {
        const res = await axios.get(`${process.env.REACT_APP_BACKEND_URI}/admin/users?type=${usertype}&keyword=${keyword}&page=${page}`, axiosConfig);
        return res.data;
      }
    } catch (e) {
      console.log(e);
    }
  }
)
export const getAllListingsByType = createAsyncThunk(
  'admin/getAllListingsByType',
  async (props) => {
    const { type, keyword, page } = props;
    try {
      if (keyword) {
        const res = await axios.get(`${process.env.REACT_APP_BACKEND_URI}/admin/listings/${type}?keyword=${keyword}`, axiosConfig);
        return res.data;
      } else {
        const res = await axios.get(`${process.env.REACT_APP_BACKEND_URI}/admin/listings/${type}?page=${page}`, axiosConfig);
        return res.data;
      }
    } catch (e) {
      console.log(e);
    }
  }
)

export const updateUserProfileByAdmin = createAsyncThunk(
  'profile/updateUserProfileByAdmin',
  async (data) => {
    try {
      const { id, ...rest } = data
      const userData = JSON.stringify(rest)
      const res = await axios.patch(`${process.env.REACT_APP_BACKEND_URI}/admin/update-user/${id}`, userData, axiosConfig)
      if (res.status === 200) {
        const res = await axios.get(`${process.env.REACT_APP_BACKEND_URI}/admin/users`, axiosConfig);
        return res;
      }
      else {
        throw new Error("Failed!")
      }
    } catch (e) {
      return e.response?.data?.message
    }
  }
)

export const getDetails = createAsyncThunk("admin/getDetails", async () => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_BACKEND_URI}/admin/details`,
      axiosConfig
    );
    return res.data;
  } catch (e) {
    console.log(e);
  }
});

export const getAllBookingsForAdmin = createAsyncThunk("admin/getAllBookingsForAdmin", async (props) => {
  const { keyword, page } = props;
  try {
    if (keyword) {
      const res = await axios.get(`${process.env.REACT_APP_BACKEND_URI}/admin/get-bookings?id=${keyword}`, axiosConfig);
      return res.data;
    } else {
      const res = await axios.get(`${process.env.REACT_APP_BACKEND_URI}/admin/get-bookings?page=${page}`, axiosConfig);
      return res.data;
    }
  } catch (e) {
    console.log(e);
  }
});

export const getUserListings = createAsyncThunk(
  'admin/getUserListings',
  async (id) => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_BACKEND_URI}/admin/listing/${id}`, axiosConfig);
      return res.data;
    } catch (e) {
      console.log(e);
    }
  }
);

export const getUserBookings = createAsyncThunk(
  'admin/getUserBookings',
  async (id) => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_BACKEND_URI}/admin/booking/${id}`, axiosConfig);
      return res.data;
    } catch (e) {
      console.log(e);
    }
  }
);

export const updateListingByAdmin = createAsyncThunk(
  'profile/updateListingByAdmin',
  async (data) => {
    try {
      const { id, type, ...rest } = data
      const userData = JSON.stringify(rest)
      const res = await axios.patch(`${process.env.REACT_APP_BACKEND_URI}/admin/update-listing/${type}/${id}`, userData, axiosConfig)
      if (res.status === 200) {
        const res = await axios.get(`${process.env.REACT_APP_BACKEND_URI}/admin/listings/${type}`, axiosConfig);
        return res;
      }
      else {
        throw new Error("Failed!")
      }
    } catch (e) {
      return e.response?.data?.message
    }
  }
)

export const getAgentsTable = createAsyncThunk(
  'admin/getAgentsTable',
  async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_BACKEND_URI}/admin/agent-table`, axiosConfig);
      return res.data;
    } catch (e) {
      console.log(e);
    }
  }
);

export const getUserSubscriptions = createAsyncThunk(
  'admin/getUserSubscriptions',
  async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_BACKEND_URI}/admin/get-all-subscriptions-users`, axiosConfig);
      return res.data;
    } catch (e) {
      console.log(e);
    }
  }
);

const intialValue = {
  users: null,
  agentsTable: null,
  bookings: {
    bookings: [],
    count: null,
    status: null,
    page: null,
    total: null
  },
  listings: {
    listings: [],
    count: null,
    status: null,
    page: null,
    total: null
  },
  subscriptions: {
    subscriptions: [],
    status: null,
    page: null,
    total: null
  },
  details: null,
  status: 'idle',
  error: null,
}

const adminSlice = createSlice({
  name: 'admin',
  initialState: intialValue,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllUsers.pending, (state) => {
        state.status = "pending";
        state.error = null;
      })
      .addCase(getAllUsers.fulfilled, (state, action) => {
        state.status = "fulfilled";
        state.users = action.payload?.data;
        state.error = null;
      })
      .addCase(getAllUsers.rejected, (state, action) => {
        state.status = "error";
        state.users = null;
        state.error = action.payload;
      })
      .addCase(updateUserProfileByAdmin.pending, (state) => {
        state.status = "pending";
        state.error = null;
      })
      .addCase(updateUserProfileByAdmin.fulfilled, (state, action) => {
        state.status = "fulfilled";
        state.users = action.payload?.data?.data;
        state.error = null;
      })
      .addCase(updateUserProfileByAdmin.rejected, (state, action) => {
        state.status = "error";
        state.users = null;
        state.error = action.payload;
      })
      .addCase(updateListingByAdmin.pending, (state) => {
        state.status = "pending";
        state.error = null;
      })
      .addCase(updateListingByAdmin.fulfilled, (state, action) => {
        state.status = "fulfilled";
        state.listings.listings = action.payload?.data?.data?.listings;
        state.error = null;
      })
      .addCase(updateListingByAdmin.rejected, (state, action) => {
        state.status = "error";
        state.listings.listings = null;
        state.error = action.payload;
      })
      .addCase(getAllListingsByType.pending, (state) => {
        state.listings.status = "pending";
        state.error = null;
      })
      .addCase(getAllListingsByType.fulfilled, (state, action) => {
        state.listings.status = "fulfilled";
        state.listings.listings = action.payload?.data.listings;
        state.listings.count = action.payload?.data.count;
        state.listings.total = action.payload?.data.total;
        state.listings.page = action.payload?.data.page;
        state.error = null;
      })
      .addCase(getAllListingsByType.rejected, (state, action) => {
        state.listings.status = "error";
        state.users = null;
        state.error = action.payload;
      })
      .addCase(getDetails.pending, (state) => {
        state.status = "pending";
        state.error = null;
      })
      .addCase(getDetails.fulfilled, (state, action) => {
        state.status = "fulfilled";
        state.details = action.payload?.data;
        state.error = null;
      })
      .addCase(getDetails.rejected, (state, action) => {
        state.status = "error";
        state.users = null;
        state.error = action.payload;
      })
      .addCase(getAllBookingsForAdmin.pending, (state) => {
        state.bookings.status = "pending";
        state.error = null;
      })
      .addCase(getAllBookingsForAdmin.fulfilled, (state, action) => {
        state.bookings.status = "fulfilled";
        state.bookings.bookings = action.payload?.data.bookings;
        state.bookings.count = action.payload?.data.count;
        state.bookings.total = action.payload?.data.total;
        state.bookings.page = action.payload?.data.page;
        state.error = null;
      })
      .addCase(getAllBookingsForAdmin.rejected, (state, action) => {
        state.bookings.status = "error";
        state.users = null;
        state.error = action.payload;
      })
      .addCase(getUserListings.pending, (state) => {
        state.bookings.status = "pending";
        state.error = null;
      })
      .addCase(getUserListings.fulfilled, (state, action) => {
        state.listings.status = "fulfilled";
        state.listings.listings = action.payload?.data.listings;
        state.listings.count = action.payload?.data.count;
        state.listings.total = action.payload?.data.total;
        state.listings.page = action.payload?.data.page;
        state.error = null;
      })
      .addCase(getUserListings.rejected, (state, action) => {
        state.bookings.status = "error";
        state.users = null;
        state.error = action.payload;
      })
      .addCase(getUserBookings.pending, (state) => {
        state.bookings.status = "pending";
        state.error = null;
      })
      .addCase(getUserBookings.fulfilled, (state, action) => {
        state.bookings.status = "fulfilled";
        state.bookings.bookings = action.payload?.data.bookings;
        state.bookings.count = action.payload?.data.count;
        state.bookings.total = action.payload?.data.total;
        state.bookings.page = action.payload?.data.page;
        state.error = null;
      })
      .addCase(getUserBookings.rejected, (state, action) => {
        state.bookings.status = "error";
        state.users = null;
        state.error = action.payload;
      })
      .addCase(getAgentsTable.pending, (state) => {
        state.status = "pending";
        state.error = null;
      })
      .addCase(getAgentsTable.fulfilled, (state, action) => {
        state.status = "fulfilled";
        state.agentsTable = action.payload?.data;
        state.error = null;
      })
      .addCase(getAgentsTable.rejected, (state, action) => {
        state.status = "error";
        state.error = action.payload;
      })
      .addCase(getUserSubscriptions.pending, (state) => {
        state.status = "pending";
        state.error = null;
      })
      .addCase(getUserSubscriptions.fulfilled, (state, action) => {
        state.subscriptions.status = "fulfilled";
        state.subscriptions.subscriptions = action.payload?.data?.data;
        state.subscriptions.total = action.payload?.data.total;
        state.subscriptions.page = action.payload?.data.page;
        state.error = null;
      })
      .addCase(getUserSubscriptions.rejected, (state, action) => {
        state.status = "error";
        state.error = action.payload;
      });
  }
})

export default adminSlice.reducer;