import React from 'react'

const Logo = (props) => {
    const {
        src,
        alt,
        height,
        width,
    } = props
    return (
        <>
            {src ?
                <img src={src} alt={alt} height={height} width={width} />
                : <div>NO-Image-Found</div>
            }
        </>
    )
}

export default Logo