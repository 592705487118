import css from "./ClientsSaysSection.module.scss";
// import avatar from '../../assets/Images/Avatar.png'
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import { Navigation, Pagination } from "swiper/modules";
import Container from "../Container/Container";
import { connect } from "react-redux";
import { useEffect } from "react";
import { getTenShowListings, getTenVenueListings } from "../../redux/reducers/landingSlice";
import { compose } from "redux";
import IconCollection from "../IconCollection/IconCollection";
import { Link } from "react-router-dom";
import image from "../../assets/Images/servicesSection.png";
import image2 from "../../assets/Images/concert-hands-up-dark-party.png";
import image3 from "../../assets/Images/heroBanner.png";

const ClientsSaysSection = (props) => {
  const { tenVenueListings, tenShowListings, getTenVenueListingsApi, getTenShowListingsApi } = props;

  useEffect(() => {
    getTenVenueListingsApi();
    getTenShowListingsApi();
  }, [getTenVenueListingsApi, getTenShowListingsApi])

  const breakpoints = {
    // when window width is >= 320px
    320: {
      slidesPerView: 1,
      spaceBetween: 10,
    },
    // when window width is >= 480px
    480: {
      slidesPerView: 2,
      spaceBetween: 10,
    },
    // when window width is >= 768px
    768: {
      slidesPerView: 3,
      spaceBetween: 20,
    },
    // when window width is >= 992px
    1100: {
      slidesPerView: 4,
      spaceBetween: 20,
    },
  };

  const ListingCard = ({ cover_image, poster, venue_name, artist_name, venue_tag, genre, venue_location, artist_location_city, total_capacity, show_title }) => {
    return (
      <div className={css.listingCard}>
        <div className={css.listingImage}>
          {(cover_image || poster)?.url ? (<img
            src={(cover_image || poster).url}
            alt={(cover_image || poster).key}
            loading="lazy"
          />
          ) : (
            <div className={css.noImage}>
              <IconCollection name="NO_IMAGE" />
              <p>NO IMAGE</p>
            </div>
          )}
        </div>
        <div className={css.cardContents}>
          <div>
            <p className={css.artistName}>
              {venue_name || show_title}
            </p>

            <p>
              {venue_tag || artist_name}
            </p>
            <p>
              {venue_location?.formatted_address || genre}
            </p>
            <p>
              {total_capacity || artist_location_city?.formatted_address}
            </p>
          </div>

        </div>
      </div>
    );
  }

  return (
    <Container>
      <div className={css.clientSays}>
        {tenShowListings?.length > 0 && <div className={css.sliderWrapper}>
          <h2>Recent Shows</h2>
          <Swiper
            breakpoints={breakpoints}
            pagination={{
              dynamicBullets: true,
            }}
            navigation={true}
            modules={[Pagination, Navigation]}
            className="mySwiper"
            slidesPerView={3}
          >
            {tenShowListings?.map(st => {
              const { id, poster, artist_name, genre, artist_location_city, show_title } = st;
              return (
                <SwiperSlide key={id}>
                  <Link to={`/l/shows/${id}`}>
                    <ListingCard
                      id={id}
                      poster={poster}
                      artist_name={artist_name}
                      genre={genre}
                      artist_location_city={artist_location_city}
                      show_title={show_title}
                    />
                  </Link>
                </SwiperSlide>
              )
            })}
          </Swiper>
        </div>}

        {tenVenueListings?.length > 0 && <div className={css.venueSliderWrapper}>
          <h2>Recent Venues</h2>
          <Swiper
            breakpoints={breakpoints}
            pagination={{
              dynamicBullets: true,
            }}
            navigation={true}
            modules={[Pagination, Navigation]}
            className="mySwiper"
            slidesPerView={3}
          >
            {tenVenueListings?.map(st => {
              const { id, cover_image, venue_name, venue_tag, venue_location, total_capacity } = st;
              return (
                <SwiperSlide key={id}>
                  <Link to={`/l/venues/${id}`}>
                    <ListingCard
                      id={id}
                      cover_image={cover_image}
                      venue_name={venue_name}
                      venue_tag={venue_tag}
                      venue_location={venue_location}
                      total_capacity={total_capacity}
                    />
                  </Link>
                </SwiperSlide>
              )
            })}
          </Swiper>
        </div>}
        {(!(tenShowListings?.length) && !(tenVenueListings?.length)) && <div className={css.clientSlider}>
          <Swiper
            breakpoints={breakpoints}
            pagination={{
              dynamicBullets: true,
            }}
            navigation={true}
            modules={[Pagination, Navigation]}
            className="mySwiper"
            slidesPerView={3}
          >
            <SwiperSlide key={1}>
              <div className={css.clientCard}>
                <img src={image} alt="" />
                <div className={css.cardContents}>
                  <h2>Echoes of Expression</h2>
                  <p>Vincent van Gogh</p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide key={2}>
              <div className={css.clientCard}>
                <img src={image2} alt="" />
                <div className={css.cardContents}>
                  <h2>Spectrum Unveiled</h2>
                  <p>Leonardo da Vinci</p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide key={3}>
              <div className={css.clientCard}>
                <img src={image3} alt="" />
                <div className={css.cardContents}>
                  <h2>Artistry in Motion</h2>
                  <p>Frida Kahlo</p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide key={4}>
              <div className={css.clientCard}>
                <img src={image} alt="" />
                <div className={css.cardContents}>
                  <h2>Visions Revealed</h2>
                  <p>Pablo Picasso</p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide key={5}>
              <div className={css.clientCard}>
                <img src={image2} alt="" />
                <div className={css.cardContents}>
                  <h2>Palette Perspectives</h2>
                  <p>Claude Monet</p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide key={6}>
              <div className={css.clientCard}>
                <img src={image3} alt="" />
                <div className={css.cardContents}>
                  <h2>Canvas Conversations</h2>
                  <p>Georgia O'Keeffe</p>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>}
      </div>
    </Container>
  );
};

const mapStateToProps = (state) => {
  const tenVenueListings = state?.landing?.tenVenueListings;
  const tenShowListings = state?.landing?.tenShowListings;
  return { tenShowListings, tenVenueListings }
}
const mapDispatchToProps = dispatch => ({
  getTenVenueListingsApi: () => dispatch(getTenVenueListings()),
  getTenShowListingsApi: () => dispatch(getTenShowListings())
})

export default compose(connect(mapStateToProps, mapDispatchToProps))(ClientsSaysSection)